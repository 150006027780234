// better to get color variable from _color.sass
$logo-color: #5d7f9d;
$background-color1: #42484e;
$border-color: #6c757d;
$gray1: #9a9a9a;
$white: white;
@mixin btn {
  border: none;
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

@mixin followingBtn {
  width: 100%;
  height: 100%;
}

.btn--follow {
  @include btn;
  @include followingBtn;
  background-color: $logo-color;
  color: $white;
}

.btn--unfollow {
  @include btn;
  @include followingBtn;
  background-color: $gray1;
}

.btn--icon {
  @include btn;
  margin: 0.7rem;
  background-color: inherit;
  color: $white;
}

.footer-wrapper {
  display: flex;
  // border: solid 1px $border-color;
  border-left: none;
  width: 100%;
  height: 100%;
}

.DraftEditor-root {
  margin: 0 auto;
  border: none;
  background-color: $background-color1;
  padding: 0.5rem 1rem;
  width: 100% !important;
  height: 100% !important;
  resize: none;
  overflow-y: scroll;
  color: $white;
  .public-DraftEditorPlaceholder-root {
    color: $gray1;
  }
  .DraftEditor-editorContainer {
    height: 100% !important;
  }
}

.linkText {
  color: #abd1e3;
  text-decoration: underline;
}

.linkText:hover {
  color: #abd1e3;
}
