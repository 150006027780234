/* renderExploreList */
.user-list-wrapper {
  overflow-y: scroll;
  border-radius: 0;
  margin: 10px 0 0 0;
}

/* exploreList  */
.exploreList_user_name {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
}

.exploreList_school_name {
  font-size: 0.8rem;
  margin: 0;
  color: #ffffff;
}

.exploreList_major_name {
  font-size: 0.8rem;
  color: #ffffff;
}
