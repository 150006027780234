// .panelWrapper {}

.tag-panel-wrapper {
  position: absolute;
  z-index: 99;
  bottom: 75px;
  margin: 0;
  width: 100%;
  max-height: 11rem;
  padding: 0.5rem;
  list-style: none;
  overflow: scroll;

  li {
    display: flex;
    align-items: center;
    padding: 1rem;
    height: 3rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  img {
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
  }

  .user-name {
    margin-left: 2rem;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
  }
}
