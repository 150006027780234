.emoji-mart {
  position: absolute;
  /* bottom: 75px; */
  width: 100%;
}

.emoji-mart-emoji:focus {
  outline: none;
}

.emoji-picker-wrapper {
  position: relative;
  width: 100%;
}

.emoji-picker-wrapper .cross-button {
  position: absolute;
  /* top: -122px; */
  left: 20px;
  cursor: pointer;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  color: gray;
}

.emoji-picker-wrapper .emoji-mart-preview-emoji {
  left: 45px;
}

.emoji-picker-wrapper .emoji-mart-preview-data {
  left: 105px;
}
