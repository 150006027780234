#wrapper {
  width: 100%;
  position: relative;
}

#container {
  width: 100%;
  flex: 1;
  display: flex;
  border-color: rgb(204, 204, 204);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: white;
}

#input-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
}

#input-value {
  position: absolute;
  padding: 0px 8px;
  color: rgb(85, 85, 85);
  font-size: 14px;
}

.search-input {
  width: 100%;
  z-index: 10;
  background-color: #ffffff00;
  flex-wrap: wrap;
  min-height: 38px;
  box-sizing: border-box;
  outline: none !important;
  border: none !important;
  margin: 0px 8px;
}

.dropdown-button-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}

#indicator-separator {
  align-self: stretch;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.dropdown-button {
  background-color: #ffffff00;
  border: none;
  outline: none;
  color: rgb(204, 204, 204);
  display: flex;
  padding: 8px;
  transition: color 150ms ease 0s;
  box-sizing: border-box;
  font-size: 18px;
}

#dropdown-container {
  width: 100%;
  z-index: 100;
  position: absolute;
  margin-top: 2px;
  max-height: 136px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-color: rgb(204, 204, 204);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 1px;
  overflow-y: scroll;
}

#nooptions-message {
  text-align: center;
  color: #808080;
  padding: 8px;
  font-size: 14px;
}

#dropdown-item {
  z-index: 100;
  color: rgb(77, 122, 180);
  padding: 8px;
  font-size: 14px;
}

#dropdown-item:hover {
  background-color: rgba(38, 132, 255, 0.507);
}
