.auth__card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;

  .auth__card-header {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.auth__button {
  color: white !important;
  padding: 10px 12px !important;
  font-weight: bold !important;
  border-width: 2px !important;
  border-radius: 10px !important;
  > div {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      &:first-child {
        margin-right: 7px;
        flex: 0 1 0%;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
}

.auth__submit-button {
  color: white !important;
  background-color: #5d7e9d !important;
  padding: 8px 10px !important;
  font-weight: bold !important;
  border: 2px solid gray !important;
  border-radius: 5px !important;
}
