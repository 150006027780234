.chaticon-size {
  width: 60px;
  height: 60px;
}

.chaticon-wrap-left {
  width: 60px;
  float: left;
}

.chaticon-wrap {
  width: 60px;
}

.bubble-wrap {
  padding-left: 10px;
}

.bubble-space {
  padding: 0.75rem !important;
}

@media screen and (max-width: 767px) {
  .bubble-space {
    padding: 0.5rem !important;
  }

  .chaticon-wrap-left {
    width: 50px;
  }

  .chaticon-wrap {
    width: 50px;
  }

  .chaticon-size {
    width: 40px;
    height: 40px;
  }

  .bubble-wrap {
    padding-left: 0px;
  }
}
