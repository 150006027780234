.sign-up-success-page {
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 10;
  .sign-up-success-page__title {
    letter-spacing: 0.2rem;
    font-family: sans-serif;
  }
  .sign-up-success-page__text {
    color: #2e302d;
  }
}
