.sign-up-page {
  height: 100vh;
  -webkit-appearance: none;
  .sign-up-page__title {
    color: #5d7f9d;
    letter-spacing: 0.5rem;
    font-family: sans-serif;
  }
  #sign-up-container {
    height: 520px;
    position: relative;
    .sign-up-page__last-step {
      height: 300px;
      .sign-up-page__last-step__pic {
        width: 200px;
        height: 200px;
      }
      label {
        width: 200px;
        cursor: pointer;
        input {
          display: none;
        }
      }
    }
    .sign-up-page__process-btn-container {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

#formHeader {
  -webkit-appearance: none;
}

.hobbies {
  -webkit-appearance: none;
}
