body {
  margin: 0;
  padding: 0;
}

img {
  object-fit: cover;
}

.profileImg {
  max-width: 240px;
}

@media screen and (max-width: 576px) {
  .exploreUsers {
    display: none;
  }
  .base-card {
    margin: 0.5rem;
  }
}

.base-card {
  border-radius: 1rem;
  margin: 0.5rem 0.3rem 0 0.3rem;
  padding: 0 0 0 0;
  background: #ffffff;
}

.username {
  font-size: 1.4rem;
  padding-top: 1rem;
  font-weight: 600;
  font-family: 'Roboto,sans-serif';
  /* color: #377ef2; */
}

.schoolMajor {
  font-size: 1.2rem;
  font-family: 'Roboto,sans-serif';
}

.baseLogo {
  max-width: 30px;
}

.dropDown {
  .btn-dark {
    border: none;
    padding: 5;
  }

  #dropdown-settings-link {
    background-color: #1c1f21;
    border-color: #323638;
    color: #d1cdc7;
  }

  #dropdown-settings-link:hover {
    background-color: #27292b;
    border-color: #384042;
    color: #d1cdc7;
  }

  .dropdown-menu.show,
  .raised {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #191b1c;
    color: #353534;
  }
}

.dropdown-divider:last-child {
  display: none;
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 36, 38, 0.5);
}

.sticky-offset-nav {
  top: 82px !important;
}

.camera-edit {
  border: solid #5d7f9d 1.5px;
  color: #5d7f9d;
  background-color: white;
  font-size: 8.5vw;
  padding: 0.5rem;
  border-radius: 50%;
  position: absolute;
  right: 1%;
  bottom: 9%;
  max-width: 40px;
  max-height: 40px;
}

.camera-edit-2 {
  border: solid #5d7f9d 1.5px;
  color: #5d7f9d;
  background-color: white;
  font-size: 2.5rem;
  padding: 0.5rem;
  border-radius: 25px;
  position: absolute;
  right: 0px;
  bottom: -10px;
}

.button-icon {
  border: solid #2e302d 1.5px;
  color: #5d7f9d;
  background-color: white;
  font-size: 3rem;
  border-radius: 100%;
}

.base-btn :hover {
  color: #5d7f9d;
  background-color: white;
}

.pad-sm {
  padding: 0.8rem;
}

.pad-sm2 {
  padding: 0.8rem 0.3rem;
}

.thumbnail-edit {
  position: relative;
}

.top-logo-text {
  display: inline-block;
  top: 20%;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 90%;
}

a {
  text-decoration: none !important;
}
