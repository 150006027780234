.sidebar .gr-1 {
  display: grid;
  margin-left: 5px;
  margin-right: 5px;
  grid-gap: 5px;
}

.sidebar .gr-2 {
  display: grid;
  margin-left: 5px;
  margin-right: 5px;
  grid-gap: 5px;
}

.sidebar .gr-3 {
  margin-left: 5px;
  margin-right: 5px;
}

.sidebar .gr-4 {
  display: grid;
  margin-left: 5px;
  margin-right: 5px;
  grid-gap: 5px;
}

.sidebar .gr-1 > div {
  background-color: #191b1c;
  color: #d1cdc7;
  text-align: left;
  padding: 15px 15px;
  font-size: 18px;
}

.sidebar .gr-2 > div {
  background-color: #191b1c;
  color: #d1cdc7;
  text-align: left;
  padding: 15px 15px;
  font-size: 18px;
  cursor: pointer;
}

.sidebar .gr-3 > div {
  background-color: #191b1c;
  color: #d1cdc7;
  text-align: left;
  padding: 15px 15px;
  font-size: 18px;
  cursor: pointer;
}

.sidebar .gr-4 > div {
  background-color: #191b1c;
  color: #d1cdc7;
  text-align: left;
  padding: 15px 15px;
  font-size: 18px;
  cursor: pointer;
}

.sidebar .namePosition {
  margin-left: 10px;
}

.sidebar .image {
  border-radius: 50%;
}

.sidebar .pencil {
  cursor: pointer;
}

.sidebar .stat {
  margin-left: 15px;
  cursor: pointer;
}

.sidebar .posts {
  cursor: pointer;
}

.sidebar .addUser {
  position: absolute;
  margin-top: 30px;
  margin-left: 285px;
  font-size: 25px;
  color: gray;
}

.sidebar .addUserClick {
  cursor: pointer;
}

.sidebar .exitChat {
  position: absolute;
  margin-top: 30px;
  margin-left: 330px;
  font-size: 25px;
  color: gray;
}

.sidebar .exitChatClick {
  cursor: pointer;
}

.exitBtn {
  margin-right: 1em;
}

.posts > span:first-child,
.media > span:first-child {
  margin-right: 20px;
}

.posts > .arrow,
.media > .arrow {
  font-weight: bold;
  font-size: 20px;
  color: #978e80;
}

#dropdown-basic-button {
  width: 100%;
  height: 100%;
}

#dropdown-basic-button.dropdown-toggle::after {
  display: none;
  content: none;
}

#tabs-tab-DocumentLink.nav-link.active,
#tabs-tab-JustLink.nav-link.active,
#tabs-tab-MediaLink.nav-link.active {
  background-color: #191b1c;
  border-color: #383d3f;
}

#tabs-tab-DocumentLink.nav-link:hover {
  border-color: #383d3f;
}

#tabs-tab-JustLink.nav-link:hover {
  border-color: #383d3f;
}

#tabs-tab-MediaLink.nav-link:hover {
  border-color: #383d3f;
}

.nav.nav-tabs.nav-fill {
  border-bottom-color: #383d3f;
}

/* .chatDescription {
    text-decoration: underline;
} */

.groupDescription {
  max-width: inherit;
  word-break: break-all;
}
