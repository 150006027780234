/* Applied component :
    ChatPage.tsx
    ChatList.tsx
    RankingPage.tsx
    Account.tsx
    UserList.tsx
    UserProfile.tsx
*/

.bgGray-1 {
  background-color: #3c4246 !important;
}

.bgGray-2 {
  background-color: #2e302d !important;
}

.bgGray-3 {
  background-color: #393835 !important;
}

.bgGray-4 {
  background-color: #32312e !important;
}

.bgGray-5 {
  background-color: #9a9a9a;
}

.bgBlack-1 {
  background-color: #111111;
}

.bgWhite {
  background-color: white;
}

.bgBaseColor {
  background-color: #5d7f9d !important;
}

/* Red used for the test */

.bgRed {
  background-color: red;
}

.colorWhite {
  color: white !important;
}

.colorGray-1 {
  color: #9a9a9a !important;
}
