#landingDiv {
  height: 100vh;
  background-color: #3c4246;
}

.chat-body {
  flex: 75%;
  max-width: 75%;
}

.chat-body--shrunken {
  flex: 0 0 47%;
  max-width: 47%;
  z-index: 10;
}

.sidebar--open {
  flex: 28%;
  max-width: 28%;
}

.sidebar--closed {
  flex: 0;
  max-width: 0;
}

.schoolschart-wrap {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .chat-body {
    flex: 100%;
    max-width: 100%;
    position: relative;
    right: 0%;
  }
  .chat-body--shrunken {
    flex: 100%;
    max-width: 100%;
    position: relative;
    right: 100%;
  }
  .sidebar--open {
    flex: 100%;
    max-width: 100%;
  }
}

.chatListHover:hover {
  background-color: #1111 !important;
}
