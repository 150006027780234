/* common side nav */
.newChatSideNav,
.chatPage {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgb(230, 225, 226);
  overflow-y: scroll;
  padding-top: 30px;
}

.newChatSideNav {
  transition: 0.3s;
}

.chatPage {
  height: 100%;
  width: 100%;
  left: auto;
}

.newChatSideNav .closeBtn {
  position: absolute;
  top: 30px;
  left: 20px;
  font-size: 20px;
  cursor: pointer;
}

.newChatSideNav .buttons {
  cursor: pointer;
  width: 40px;
}

/* group nav */
.groupPanel {
  z-index: 5;
}
.infoText {
  color: #5d7f9d;
  text-align: center;
  padding: 0 1vw;
}
.chatBtn {
  background-color: #5d7f9d;
  color: white;
  padding: 10px 60px;
  border-radius: 30px;
  margin-top: 30px;
  display: block;
  margin: auto;
}
.chatBtn:hover {
  background-color: rgb(99, 138, 172);
}
.chatBtn:focus {
  outline-width: 0;
}
.chat-button-disabled {
  background-color: #8ca5bb;
  color: white;
  padding: 10px 60px;
  border-radius: 30px;
  margin-top: 30px;
  display: block;
  margin: auto;
  border: 1px solid #8ca5bb;
}
.inputGroup {
  margin: auto;
  display: block;
  text-align: center;
  min-width: 200px;
  padding-bottom: 10px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid black;
  background-color: rgb(230, 225, 226);
}
.inputGroup:focus {
  outline-width: 0;
}

/* group member nav */
.groupMemberList {
  height: 56px;
}
.memberListImage {
  position: relative;
  margin-right: 16px;
  line-height: 40px;
}
.memberListDeleteBtn {
  position: absolute;
  right: 0;
  margin-top: 20px;
  margin-right: -4px;
}
.goBtn {
  background-color: #5d7f9d;
  color: white;
  font-size: 20px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
}
.goBtn:focus {
  outline-width: 0;
}
.group-body-open {
  position: absolute;
  width: 100%;
}
.group-body-close {
  position: absolute;
  left: -100%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .chat-responsive-open {
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .chat-responsive-close {
    position: absolute;
    left: -100%;
    width: 100%;
  }

  nav {
    display: none;
  }
}
