#MessageList {
  height: calc(100% - 129px);
  background-color: rgb(12, 11, 11);
}

.replyMsgWrapper {
  position: absolute;
  bottom: 75px;
  width: 100%;
}

.mention {
  background-color: rgba(255, 255, 255, 0.6);
  direction: ltr;
  color: #007bff;
  font-weight: bold;
  unicode-bidi: bidi-override;
}

.hashtag {
  color: #5fb88a;
  font-weight: bold;
}

.htmlMessage p {
  margin-bottom: 0;
}

a.bubbleLink {
  color: rgb(241, 237, 237);
  text-decoration: underline;
}

a.bubbleLink:hover {
  color: rgb(241, 237, 237);
}

.backbtn-space {
  display: none !important;
  padding-left: 0 !important;
  text-decoration: none !important;
}

.chatroom-title {
  font-size: 17px;
  line-height: 1;
  font-weight: bold;
  padding-left: 0 !important;
  z-index: 2;
}

.backbtn-icon {
  font-size: 25px;
  color: #ffffff;
}

@media screen and (min-width: 879px) and (max-width: 1072px) {
  .chatroom-title {
    font-size: 16px;
    line-height: 1;
  }
}

@media screen and (min-width: 767px) and (max-width: 879px) {
  .chatroom-title {
    font-size: 13px;
    line-height: 1;
  }
}

@media screen and (max-width: 767px) {
  /* changed from 14px to 12px */
  .chatroom-title {
    font-size: 12px;
  }
  .icon-style {
    padding: 0px !important;
  }
  .backbtn-icon {
    font-size: 20px;
  }
  .backbtn-space {
    display: block !important;
  }
  .btn-space-left {
    padding: 0 !important;
  }
}
.scroll-content {
  padding-bottom: 0 !important;
}
.detailDiv {
  position: sticky;
  top: 0;
  padding-top: 0 !important;
}
.chatFooterDiv {
  position: sticky;
  bottom: 0;
  padding-bottom: 0 !important;
  margin-bottom: 75;
}

.chatDiv {
  scroll-behavior: contain;
  margin-top: 54;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
