@media (min-width: 1440px) {
  /* change col size 4 -> 3 */
  #mainDiv > .col-xl-4 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* change col size 8 -> 9 */
  #mainDiv > .col-xl-8 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    position: fixed;
  }
}
