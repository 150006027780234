.msgDetailSideNavContents {
  width: 90%;
  margin: 10px auto;
  padding: 20px;
}

.msgData {
  width: 70%;
  border-radius: 10px;
  background-color: white;
  overflow-wrap: break-word;
}

.msgDetailSideNavContents p {
  margin-left: 20px;
}

.msgDetailSideNavContents h5 {
  margin-left: 10px;
}

.reportBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.reportBtn {
  margin-top: -16px;
}

.chatSetting_right {
  float: right;
  border: solid 0px grey;
  border-radius: 0px 4px 4px 0px;
}
