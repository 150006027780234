.sidebar {
  height: 100%;
  width: 28%;
  background-color: #282b2c;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  overflow-y: scroll;
  transition: 0.3s;
  transform: translateX(100%);
  color: #d1cdc7;

  &.open {
    transform: translateX(0%);
  }

  header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #282b2c;
    height: 54px;
    &.boundary {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  button.closebtn {
    font-size: 20px;
    border: none;
    background-color: transparent;
    color: #e8e6e3;

    &.arrow {
      padding-left: 10px;
      font-size: 25px;
    }
  }

  button:focus {
    outline: 5px auto #956201;
  }
}

@media screen and (max-width: 767px) {
  .sidebar {
    left: 0;
    width: 100%;
  }
}
